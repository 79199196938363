import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, Stones, StoneStyles } from 'components/icons'
import { Nav } from 'components/nav'
import { AI, BigData, Blockchain, Distributed, FullStack, Mobile, SystemSoftware, TechSection, VR } from '../components/icons/Technologies'
import { BackgroundWithGrid } from '../components/layout'
import { LdJsonLogo, HeaderLink } from '../components/semanticdata'
import Analytics from '../components/analytics'

const TeamPage = () => (
  <Layout className="bg-primary-2 text-primary-3">
    <Analytics />
    <SEO title="Team Tumba" pageUrl="/team/" description="We, the people in Tumba, unite top-notch knowledge and prowess. We've developed innovative in-house products. We've evolved technological ventures to digital craftsmanship and business excellence." />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 pt-6 mb-72 sm:h-48">
        <HeaderLink className="text-primary-1" />
      </Header>
      <BackgroundWithGrid image="team-hero" gridRow={1} />
      <Deco decoType={2} inner after gridRow={1} />
      <Section gridRow={2}>
        <h1 className="text-left text-primary-1 text-4xl lg:text-5xl">Team</h1>
      </Section>
      <Section className="z-20 pt-12 pb-48 grid gap-12 grid-cols-6 lg:grid-cols-12" gridRow={3}>
        <p className="col-span-6 text-xl lg:text-2xl">
          It took us 10 years to reach the summit where we stand now. We, the people in Tumba, unite top-notch knowledge and prowess. We've developed innovative in-house products. We've evolved technological ventures to digital craftsmanship and business
          excellence.
        </p>
        <div className="col-span-5">
          <p>At Tumba we keep it simple - we adjust to your agile work process, provide predictable cost and ensure sustainable and unmatched product growth. Internally we follow the OKR methodology.</p>
          <p className="pt-6">
            We keep our teams small, senior software generalists, zero personnel turnover. Team Formats vary between 3-, 5- and 7 persons. Client engagements are 36 months on average. All teammates have 100% client-facing roles and become an integral part of
            development and communication.
          </p>
        </div>
        <div className="col-span-6">
          <h2 className="col-span-5 text-2xl lg:text-3xl">Technologies</h2>
          <div className="mt-10 grid col-gap-4 row-gap-8 grid-cols-3">
            <TechSection Icon={FullStack} rotate={-75} text="Full Stack" />
            <TechSection Icon={Mobile} rotate={0} text="Mobile" />
            <TechSection Icon={BigData} rotate={90} text="Big Data" />
            <TechSection Icon={AI} rotate={0} text="Machine Learning" />
            <TechSection Icon={VR} rotate={90} text="AR &amp; VR" />
            <TechSection Icon={Distributed} rotate={0} text="Distributed Systems" />
            <TechSection Icon={Blockchain} rotate={-75} text="Blockchain" />
            <TechSection Icon={SystemSoftware} rotate={90} text="System Software" />
          </div>
        </div>
        <div className="col-span-5">
          <h2 className="text-2xl lg:text-3xl">Products</h2>
          <p className="py-6 pt-10">We developed an in-house product - an automated device provisioning to dedicated devices or kiosks. We are working on a heart training technology built with user privacy in mind.</p>
          <p>We are open for partnerships under H2020 and looking for investment opportunities.</p>
        </div>
      </Section>
      <Deco before after className="bg-primary-1" gridRow={4} />
      <Section className="z-20 pt-12 grid gap-12 grid-cols-3 text-primary-2" gridRow={4}>
        <h2 className="col-span-3 text-2xl lg:text-3xl">Success stories</h2>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            To be able to contract a team of extremely talented engineers is rare enough, but to find a group that cares as much about your product as anyone at your company, if not more— that is truly special— and this is what Tumba brought to us. Without
            their efforts, combined with this kind of consideration for the work, we would have never enjoyed the level of success that we did.
          </p>
          <p className="mt-8">Jordan, VP Product</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            It's a delight working with Tumba, the quality of code is outstanding, even more the quality of the communication. Here's highest praise for an external company I can give: It's 100% like we're all part of the same team!
          </p>
          <p className="mt-8">Roben, Engineering Manager</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            The engineers are not only great developers but they're also openly collaborative and communicative. They're fully invested in shipping a high-quality product that our users will love. We know they care not just about their day-to-day tasks but how
            those fit into the larger strategy for The Wall Street Journal.
          </p>
          <p className="mt-8">Michael, VP Engineering</p>
        </div>
      </Section>
      <Section className="z-20 pt-40 pb-24 lg:pb-40 grid gap-12 grid-cols-6 lg:grid-cols-11" gridRow={5}>
        <div className="col-span-6 lg:col-span-11">
          <Stones className="h-20 overflow-visible" stoneClasses="transform origin-center box-fill" styles={StoneStyles[3]} />
          <p className="pt-6 text-2xl lg:text-3xl">Explore</p>
        </div>
        <Link to="/services/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Services</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/industries/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Industries</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/careers/" className="pt-8 col-span-3 flex items-baseline text-primary-1">
          <span className="text-lg">Careers</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
      </Section>
      <Deco before decoType={1} gridRow={6} className="bg-primary-1" />
      <Nav className="z-20 text-primary-2" gridRow={7} />
    </MainWithMenu>
  </Layout>
)

export default TeamPage
